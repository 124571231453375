import React, { useEffect, useReducer, useState } from "react";
import dayjs from "dayjs";
import axios from "../../../axios/axios";

import PageHeader from "../../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DirectionsIcon from "@mui/icons-material/Directions";

import ColorizeIcon from "@mui/icons-material/Colorize";
import { InputAdornment, Box, Paper, TableContainer } from "@mui/material";

import DataGrid from "../../../components/useDataGrid";
import Popup from "../../../components/Popup";
import Controls from "../../../components/Controls";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Notification from "../../../components/Notification";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../../components/useForm";
// import LabReportPrint from "./LabReportPrint";

import * as GenServices from "../../../helper/GenServices";
import { useSelector, useDispatch } from "react-redux";
import { addLabTestListMasterData } from "../../../redux/actions";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

export default function Regn_Report() {

  
  
  const [loading, setLoading] = useState(false);
  const [consultant, setConsultant] = useState([]);

  //   const dispatch = useDispatch();
  // const AllsubHeadings = useSelector((state) => state.subHeadingList_Reducer.mainHeading);

  //   const hook_AllTestList = useSelector((state) => state.LabTestList_Reducer.testData);

  // const records = useSelector((state)=> state.LabTestList_Reducer.testData);
  //console.log("records==>  ", records);

  // const [formValues, setFormValues] = useReducer((state, newState)=>( ({...state, ...newState}), initialValues ));
  //   const [allLabTestList, setAllLabTestList] = useState([]);

  const [hid] = useState(2);

  // const [records, setRecords] = useState([]);
  const [originalReportData, setOriginalReportData] = useState([]);
  const [reportData, setReportData] = useState([]);

  const [searchVal, setSearchVal] = useState("");

  const [totalValue, setTotalValue] = useState(0);

  // const [formValues, setFormValues] = useState({
  //   startdate: dayjs(),
  //   enddate: dayjs(),
  // });

  const [formValues, setFormValues] = useState({
    // consultant: "ALL",
    startdate: dayjs(),
    enddate: dayjs(),
  });

  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  //   const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);
  // const { errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  // const getConsultants = async () => {
  //   const rrr = await GenServices.getConsultants(hid);
  //   console.log("getConsultants ------", rrr);
  //   const data = rrr.map((item) => ({ val: item.text, text: item.text }));
  //   data.push({ val: "ALL", text: "ALL" });
  //   console.log("getConsultants data ------", data);

  //   setConsultant(data);
  //   // return rrr;
  // };

  // useEffect(() => {
  //   getConsultants();
  // }, []);

  const getReport = async () => {
    //setLoading(true);
    // validate(initialValues);

    try {
      //Below report also used in
      //const res = await axios.get("/regn");
      const res = await axios.get(
        `/regn/report/?hid=${hid}&startdate=${formValues.startdate}&enddate=${formValues.enddate}`
      );
      // console.log("getReport  ===>", res.data);

      if (res) {
        setOriginalReportData(res.data);
        setReportData(res.data);
        setSearchVal("");

        // let totalPaidRs = res.data.reduce((acc=0, obj) => {
        //   console.log(obj.paidRs)
        //   return acc += parseFloat(obj.paidRs);
        // });
        // let totalRs = res.data.reduce((sum, row) => sum + parseFloat(row.paidRs), 0);
        // let totalRs = res.data.reduce((sum, row) => sum + row.paidRs, 0);

        setTotalValue(res.data.length);

        // console.log("setOriginalReportData ===>", res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    // console.log("formValues=", formValues);
    getReport();
  }, [formValues]);

  //_id, mrNo, opdNo, visitNo, opdDate, validDays, validUpto, patientType, depttName, depttAnnualNo,consultant, unitName,
  //   billNo, billDate:opdDate, paidRs,
  //   name,soDo, fhName, gender, age, pMobNo, email, pAddress

  const columns = [
    //{ field: "regNo", headerName: "#", width: 90, filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
    // { field: "name", headerName: "Full name", width: 150, editable: true },
    {
      field: "createdAt",
      headerName: "Regn Date",
      width: 170,
      valueGetter: (item) => dayjs(item.row.createdAt).format("DD/MM/YYYY hh:mm a"),
    },
    { field: "mrNo", headerName: "MRNo", width: 100 },
    { field: "regNo", headerName: "RegNo", width: 100 },
    { field: "name", headerName: "Full name", width: 200 },
    { field: "fhName", headerName: "F/H Name", width: 150 },
    { field: "age", headerName: "Age", type: "number", width: 100, valueGetter: (item) => item.row.age },
    { field: "gender", headerName: "Gender", width: 80 },
    { field: "email", headerName: "Email", width: 100 },
    { field: "pMobNo", headerName: "Mobile No.", width: 120, valueGetter: (item) => item.row.mobileNo.pMobNo },
    { field: "address", headerName: "Address", width: 200, valueGetter: (item) => item.row.pAddress.address },
    { field: "city", headerName: "City", width: 100, valueGetter: (item) => item.row.pAddress.city },
    { field: "pincode", headerName: "Pincode", width: 70, valueGetter: (item) => item.row.pAddress.pincode },
    { field: "state", headerName: "State", width: 100, valueGetter: (item) => item.row.pAddress.state },
    { field: "country", headerName: "Country", width: 100, valueGetter: (item) => item.row.pAddress.country },
    { field: "nationality", headerName: "Nationality", width: 100, valueGetter: (item) => item.row.pAddress.nationality },
     
    
    // valueGetter: (item) => `${item.row.pAddress.address || ""}, ${item.row.pAddress.city || ""}`,

    // { field: "treatmentType", headerName: "treatmentType", width: 150, editable: true },
    // { field: "department", headerName: "department", width: 150, editable: true },
 
  ];

  const handleSearch = async (e) => {
    const val = e.target.value;
    // const str = val || "";
    // console.log("BindBottomTable Value========>", val);
    setSearchVal(val);
    //if (val) {
    const filteredRows = search(originalReportData, val);
    // console.log("filteredRows =>", filteredRows);
    setReportData(filteredRows);
    //}
  };

  const [fieldNamesForSearch] = useState(["name", "fhName", "mrNo", "opdNo", "pMobNo", "email"]);

  function search(allItems, val) {
    return allItems.filter((item) => {
      return fieldNamesForSearch.some((newItem) => {
        return item[newItem].toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
    });
  }

  const cancelSearch = () => {
    setSearchVal("");
    setReportData(originalReportData);
  };

  return (
    <div>
      <PageHeader title="Registration Report" icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />} />

      {/* <Paper> */}
      {/* <MyForm onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={0} sm={0} md={4} lg={4}></Grid>
        {/* billDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="Start-Date"
            name="startDate"
            value={dayjs(formValues.startdate).format("DD/MMM/YYYY")}
            onChange={(e) => setFormValues({ ...formValues, startdate: e.target.value })}
            // error={errors.billDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>

        {/* sampleDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="End-Date"
            name="endDate"
            value={dayjs(formValues.enddate).format("DD/MMM/YYYY")}
            onChange={(e) => setFormValues({ ...formValues, enddate: e.target.value })}
            // error={errors.sampleDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>  
        {/* sampleDate */}
        <Grid item xs={2} sm={2} md={2} lg={1}>
          <Controls.Button variant="outlined" text="Go" onClick={() => getReport()} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <div style={{ textAlign: "center" }}>Total Amount Rs.:- {totalValue}</div> */}

          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip sx={{ fontSize: "15px" }} label="Total Registration:-" />
              <Chip sx={{ fontSize: "20px" }} label={totalValue} color="success" />
            </Stack>
          </Stack>
        </Grid>

        {/* <Grid item xs={6} sm={4} md={4} lg={4}>
          <Controls.Input
            label="Search"
            name="search"
            value={searchVal}
            onChange={handleSearch}
            // title="press tab key for get record"
            // onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
            // error={errors.mrNo}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={cancelSearch} color="primary" sx={{ p: "10px" }} aria-label="directions">
                    <ClearIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                    <SearchIcon />
                    {/* <DirectionsIcon /> * /}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}
      </Grid>
      {/* </Paper> */}
      <Box sx={{ mb: "50px", mt: "20px" }}>
        <DataGrid
          // heading="Patient Registration Records"
          // loading={loading}
          columns={columns}
          rows={reportData}
          showToolbar={true}
          pageSize={50}
          boxHeight={680}
        />
      </Box>
      <br></br>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
