import { useEffect, useState } from "react";
import axios from "../../axios/axios";
import { makeStyles } from "@mui/styles";
import Controls from "../../components/Controls";
import Notification from "../../components/Notification";
import Grid from "@mui/material/Grid";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Toolbar } from "@mui/material";
import DataGrid from "../../components/useDataGrid";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { removeCSV_one_row } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@mui/material";
import Import_CSV_File from "../../components/Import_CSV_File";
// import FeeHead from "./FeeHeadSub";

const initialValues = {
  feeHead: "",
  rate: 0,
  qty: 1,
  amount: 0,
  discPer: 0,
  discAmt: 0,
  taxableAmt: 0,
  cgstRate: 0,
  cgstAmt: 0,
  sgstRate: 0,
  sgstAmt: 0,
  igstRate: 0,
  igstAmt: 0,
  netAmt: 0,
  remarks: "", 
};

export default function FeeHeads_Import() {
  
  const dispatch = useDispatch();

  // const hookData = useSelector((state) => state.CSVData_Reducer.uploadeddata);
 
  const hookData = useSelector((state) => {
    return {
      AllFeeHeads: state.FeeHeadList_Reducer.feeHeadMain,
      uploadedData: state.CSVData_Reducer.uploadeddata,
    };
  });

  const [feeHeadMain_id, setFeeHeadMain_id] = useState("");
  const [mainFeeHeadList, setMainFeeHeadList] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [errors, setErrors] = useState("");

  //   console.log("hookData=====> ", hookData);

  //   useEffect(() => {}, [hookData]);

  const getMainHeads = async () => {
    // const res = await BillingServices.get--FeeHead---MainList();
    let options = [];
    try {
      const res = await hookData.AllFeeHeads;
      // console.log("getMainHeads in Fee HeadSub  ===>", res);
      if (res) {
        options = await res.map((row) => {
          return { val: row._id, text: row.feeHeadMain };
        });
      }
      setMainFeeHeadList(options);
    } catch (error) {
      console.error("getMainHeads", error.message);
      setMainFeeHeadList(options);
    }
  };

  useEffect(() => {
    // getRecord();
    getMainHeads();
    // console.log("feeHeadMain_id ==>", feeHeadMain_id);
  }, []);

  const handleClick_Save = () => {
    if (feeHeadMain_id != "" && feeHeadMain_id != null) {
      hookData.uploadedData.map((row) => {
        // let item = row;
        if (!row.amount) {
          row.amount = row.rate;
        }
        if (!row.taxableAmt) {
          row.taxableAmt = row.rate;
        }
        if (!row.netAmt) {
          row.netAmt = row.rate;
        }
        if (!row.qty) {
          row.qty = 1;
        }
        if (row.feeHead != "" && row.feeHead != null && row.feeHead != undefined) {
          addFeeHead(row);
        } else {
          dispatch(removeCSV_one_row(row.id));
        }
      });
    } else {
      setErrors("Please select the Main Head");
      setNotify({ isOpen: true, message: "Please select the Main Head", type: "error" });
    }
  };

  const addFeeHead = async (data) => {
    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
    if (feeHeadMain_id != "" && feeHeadMain_id != null) {
      //This is for Update and Push new Value in feeHeads Nested Array
      try {
        // const ddd = { feeHeads: [data]};
        // const res = await axios.patch(`/fee/headlist/${feeHeadMain_id}`, ddd); //, queryobj);
        const res = await axios.patch(`/fee/headlist/subhead/${feeHeadMain_id}`, data); //, queryobj);
        // console.log("axios.patch response===>", res);
        if (res) {
          dispatch(removeCSV_one_row(data.id));
          // bindData();removeCSV_uploaded_one_row
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      console.error("aaaaaa=====>");

      setNotify({ isOpen: true, message: "Please select the Main Head", type: "error" });
    }
  };

  const handleChange_FeeHeadMain = (e) => {
    setFeeHeadMain_id(e.target.value);
    setErrors("");
    // BindBottomTable(value);
  };

  const columns = [       
    { field: "id", headerName: "id", width: 50 },
    { field: "feeHead", headerName: "feeHead", width: 200 },
    { field: "rate", headerName: "rate", width: 80 },
    { field: "qty", headerName: "qty", width: 50 },
    { field: "amount", headerName: "amount", width: 80 },
    { field: "taxableAmt", headerName: "taxableAmt", width: 100 },
    { field: "cgstRate", headerName: "cgstRate", width: 80 },
    { field: "cgstAmt", headerName: "cgstAmt", width: 80 },
    { field: "sgstRate", headerName: "sgstRate", width: 80 },
    { field: "sgstAmt", headerName: "sgstAmt", width: 80 },
    { field: "igstRate", headerName: "igstRate", width: 80 },
    { field: "igstAmt", headerName: "igstAmt", width: 80 },
    { field: "netAmt", headerName: "netAmt", width: 100 },
    { field: "remarks", headerName: "remarks", width: 100 }     
  ];

  return (
    <div>
      {/* below component set the Imported data into redux */}
      <Import_CSV_File />

      <Container sx={{ my: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={3} md={2} lg={2}></Grid>
          <Grid item xs={6} sm={6} md={4} lg={4}>
            <Controls.DropdownList
              label="Fee Head Main"
              name="feeHeadMain_id"
              value={feeHeadMain_id}
              onChange={handleChange_FeeHeadMain}
              options={mainFeeHeadList}
              error={errors}
            />
          </Grid>

          <Grid item xs={4} sm={3} md={2} lg={2}>
            <Box style={{ textAlign: "center" }}>
              <Controls.Button
                text="Save"
                //onClick={() => console.log(formValues)}
                //variant="outlined"
                //   type="submit"
                onClick={handleClick_Save}
                startIcon={<SaveRoundedIcon />}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <Grid item xs={12} sm={12} md={12} lg={12}> 
            <div className="viewer">
              {hookData === null && <>No file selected</>}
              {hookData !== null && (
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Country</th>
                        <th scope="col">Age</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <Data hookData={hookData} />  
                      {hookData.map((individualExcelData) => (
                        <tr key={individualExcelData.Id}>
                          {/* <IndividualData individualExcelData={individualExcelData}/> * /}
                          <td>{individualExcelData.Id}</td>
                          <td>{individualExcelData.FirstName}</td>
                          <td>{individualExcelData.LastName}</td>
                          <td>{individualExcelData.Gender}</td>
                          <td>{individualExcelData.Country}</td>
                          <td>{individualExcelData.Age}</td>
                          <td>{individualExcelData.Date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Grid>
         */}
      <Box>
        <div style={{ paddingLeft: "30px" }}>
          <h3>Note:</h3>
        </div>

        <ul>
          <li>CSV/Excel file header must be used as per below table</li>
          <li>
            Compulsory fields are:-<b> id feeHead rate </b>
          </li>
          <li>All header are case sensitive </li>
        </ul>
      </Box>
      <TableContainer>
      <DataGrid
        heading="Data for Import"
        columns={columns}
        rows={hookData.uploadedData}
        // is Paging={false}
        pageSize={50}
      />


        {/* <Table id="tblOpdBillChargesList" aria-label="simple Table" border="0">
          <TableHead></TableHead>
          <TableHead>
            <TableRow style={{ background: "#e9e9e9" }}>
              <TableCell align="center">id</TableCell>
              <TableCell>feeHead</TableCell>
              <TableCell align="center">rate</TableCell>
              <TableCell align="center">qty</TableCell>
              <TableCell align="center">amount</TableCell>
              <TableCell align="center">taxableAmt</TableCell>
              <TableCell align="center">cgstRate</TableCell>
              <TableCell align="center">cgstAmt</TableCell>
              <TableCell align="center">sgstRate</TableCell>
              <TableCell align="center">sgstAmt</TableCell>
              <TableCell align="center">igstRate</TableCell>
              <TableCell align="center">igstAmt</TableCell>
              <TableCell align="center">netAmt</TableCell>
              <TableCell align="center">remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hookData.uploadedData.length <= 0 ? (
              <TableRow key="987654321">
                <TableCell colSpan={14}>
                  <div style={{ padding: "60px", textAlign: "center" }}>
                    <h3> Data Not Found! </h3>{" "}
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              hookData.uploadedData.map((row) => {
                // console.log("hookData.uploadedData.map ===> ", row.id);

                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.feeHead}</TableCell>
                    <TableCell align="center">{row.rate}</TableCell>
                    <TableCell align="center">{row.qty}</TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                    <TableCell align="center">{row.taxableAmt}</TableCell>
                    <TableCell align="center">{row.cgstRate}</TableCell>
                    <TableCell align="center">{row.cgstAmt}</TableCell>
                    <TableCell align="center">{row.sgstRate}</TableCell>
                    <TableCell align="center">{row.sgstAmt}</TableCell>
                    <TableCell align="center">{row.igstRate}</TableCell>
                    <TableCell align="center">{row.igstAmt}</TableCell>
                    <TableCell align="center">{row.netAmt}</TableCell>
                    <TableCell align="center">{row.remarks}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table> */}
      </TableContainer>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
