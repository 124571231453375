import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";

import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Toolbar } from "@mui/material";

import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import { Container } from "@mui/system";

import * as BillingServices from "../../helper/BillingServices";

import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../components/useForm";

import * as GenServices from "../../helper/GenServices";

import { useSelector, useDispatch } from "react-redux";
import { setAllFeeHeadsMasterList } from "../../redux/actions";

//below code for Inner object of Array
const initialValues = {
  // _id: "",
  feeHead: "",
  rate: 0,
  qty: 1,
  amount: 0,
  discPer: 0,
  discAmt: 0,
  taxableAmt: 0,
  cgstRate: 0,
  cgstAmt: 0,
  sgstRate: 0,
  sgstAmt: 0,
  igstRate: 0,
  igstAmt: 0,
  netAmt: 0,
  remarks: "",
  isAmtEditable: false,
  isActive: true,
  staffUN: "",
  ip: "",
};

export default function FeeHead() {
  
  //Note: In this page add or update the nested feeHeads only (ie. feeHeads:[{},{}])

  const [hid] = useState(2);

  const dispatch = useDispatch();
  // const AllFeeHeads = useSelector((state) => state.FeeHeadList_Reducer.feeHeadMain);

  const hookData = useSelector((state) => {
    return {
      AllFeeHeads: state.FeeHeadList_Reducer.feeHeadMain,
      //regHeadList:state.FeeHeadList_Reducer.feeHeadMain
    };
  });

  const [feeHeadMain_id, setFeeHeadMain_id] = useState("");
  const [feeHeadMain, setFeeHeadMain] = useState("");
  const [selectedFeeHeads, setSelectedFeeHeads] = useState([]);
  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [mainFeeHeadList, setMainFeeHeadList] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const validate = (fieldValues = formValues) => {
    let temp = { ...errors };

    // console.log("fieldValues =====>", fieldValues.dob);
    if ("feeHeadMain" in fieldValues) temp.feeHeadMain = fieldValues.feeHeadMain ? "" : "required.";

    if ("feeHead" in fieldValues) temp.feeHead = fieldValues.feeHead ? "" : "required.";

    if ("rate" in fieldValues) temp.rate = parseInt(fieldValues.rate) >= 0 ? "" : "required.";

    // if ("qty" in fieldValues) temp.qty = parseInt(fieldValues.qty) >= 1 ? "" : "required.";

    // if ("amount" in fieldValues) temp.amount = parseInt(fieldValues.amount) >= 0 ? "" : "required.";

    // if ("netAmt" in fieldValues) temp.amount = parseInt(fieldValues.amount) >= 0 ? "" : "required.";

    setErrors({
      ...temp,
    });
    // console.log("temp===>", temp);
    if ((fieldValues = formValues)) return Object.values(temp).every((x) => x == "");
  };

  const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getRecord = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      const res = await axios.get("/fee/headlist"); //getAll <= here Get All MainHead + SubHead
      // console.log("getRecord by FeeHeadSub  ===>", res);
      if (res) {
        // setRecords(res.data);
        dispatch(setAllFeeHeadsMasterList(res.data));
        // console.log("res.data 1111111 ===>", res.data)
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const getMainHeads = async () => {
    // const res = await BillingServices.get---FeeHeadMainList();
    let options = [];
    try {
      const res = await hookData.AllFeeHeads;
      // console.log("getMainHeads in FeeHeadSub  ===>", res);
      if (res) {
        options = await res.map((row) => {
          return { val: row._id, text: row.feeHeadMain };
        });
      }
      setMainFeeHeadList(options);
    } catch (error) {
      console.error("getMainHeads", error.message);
      setMainFeeHeadList(options);
    }
  };

  useEffect(() => {
    getRecord();
    getMainHeads();
    // console.log("formValues Data ==>", formValues);
  }, []);

  useEffect(() => {
    // console.log("fhookData.AllFeeHeads ==>", hookData.AllFeeHeads);
    BindBottomTable(feeHeadMain_id);
  }, [hookData.AllFeeHeads]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      formValues.feeHead = formValues.feeHead.trim();
      formValues.feeHeadMain = feeHeadMain;
      // console.log("formValues ===>", formValues);
      addOrEdit(formValues);
    } else {
      setNotify({
        isOpen: true,
        message: "Please enter the value in required fields!",
        type: "error",
      });
      //  console.log(errors)
    }
  };

  const resetForm = () => {
    setFormValues(initialValues);
    // setRegnValues(initialValuesRegn);
    // setRecordForEdit(null);
    setErrors({});
    setNotify({ isOpen: false, message: "", type: "" });
    bindData();
    // setMRNoValueForReport("");
  };

  const addOrEdit = async (data) => {
    
    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
    if (feeHeadMain_id) {

      // console.log("feeHeadMain_id ====>", feeHeadMain_id)

      //This is for Update and Push new Value in feeHeads Nested Array
      try {
        // const ddd = { feeHeads: [data]};
        // const res = await axios.patch(`/fee/headlist/${feeHeadMain_id}`, ddd); //, queryobj);
        const res = await axios.patch(`/fee/headlist/subhead/${feeHeadMain_id}?hid=${hid}`, data); //, queryobj);
        // console.log("axios.patch response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Updated Successfully", type: "success" });
          if (data._id) setFormValues(initialValues);
        }
      } catch (error) {
        
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    }
    // else {
    //   //This is for Create new
    //   try {
    //     // console.log("axios Insert Start===>", data);
    //     const res = await axios.post("/fee/headlist", data);
    //     // console.log("axios Insert response===>", res);
    //     if (res) {
    //       bindData();
    //       setNotify({ isOpen: true, message: "Saved Successfully", type: "success" });
    //     }
    //   } catch (error) {
    //     console.error("error=====>", error);
    //     setNotify({ isOpen: true, message: error.message, type: "error" });
    //   }
    // }
  };

  const onDelete = async (id) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      console.log("axios.patch onDelete ===>", id);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.patch(`/fee/headlist/removehead/${feeHeadMain_id}`, { _id: id }); //, queryobj);
      // const res = await axios.delete(`/fee/headlist/removehead/${id}`);
      if (res) {
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        await getRecord();
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    // RegnServices.deletePatient(id);
  };

  const handleClickDelete = (nestedData) => {
    //if (window.confirm("Do you want to delete this record?")) {
    // console.log("data.row._id", data)
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subtitle: "You can't undo this operation",
      isConfirm: () => onDelete(nestedData._id),
    });
  };

  const openInEdit = (data) => {
    // console.log("data  openInEdit", data);
    setFormValues(data);
  };

  const bindData = async () => {
    // await hookData();
    setErrors({});
    // resetForm();
    // setRecordForEdit(null);
    // setFormValues(initialValues);
    await getRecord();
  };

  const handleChange_FeeHeadMain = (e, index) => {
    const { name, value } = e.target;
    // console.log("index =====",index.props.children)
    //let ddd = mainFeeHeadList[0].text;
    setFeeHeadMain(index.props.children);

    setFeeHeadMain_id(value);
    BindBottomTable(value);
  };

  const BindBottomTable = async (val) => {
    // console.log("BindBottomTable Value========>", val);

    if (val) {
      setSelectedFeeHeads([]);

      // const originalSelectedRows = useSelector((state) => state.FeeHeadList_Reducer.feeHeadMain);
      const originalSelectedRows = await hookData.AllFeeHeads;
      // console.log("hookData.AllFeeHeads BindBottomTable =>", originalSelectedRows);

      const filteredRows = await originalSelectedRows.filter((row) => {
        return row._id === val;
      });

      // console.log("hookData.AllFeeHeads filteredRows =>", filteredRows);

      const filteredRows2 = await filteredRows[0].feeHeads;

      const filteredRows3 = await filteredRows2.slice().reverse();

      setSelectedFeeHeads(filteredRows3);
    }
  };

  const isActive_Changed = (e, data) => {
    data.isActive = e.target.value;
    // console.log("data  aaaaaaa", data);
    addOrEdit(data);
  };
  const isAmtEditable_Changed = (e, data) => {
    data.isAmtEditable = e.target.value;
    // console.log("data  aaaaaaa", data);
    addOrEdit(data);
  };

  const get_feeHeadMain = () => {
    return feeHeadMain;
  };

  const columns = [
    {
      // field: "feeHeadMain",
      headerName: "Fee-Head-Main",
      width: 250,
      valueGetter: get_feeHeadMain,
    },

    // { field: "feeHead", headerName: "Fee-Head", width: 250 },
    { field: "feeHead", headerName: "Fee-Head-Sub", width: 300 },
    { field: "rate", headerName: "Rate", width: 100 },

    // { field: "amount", headerName: "Amount", width: 100 },
    // {
    //   field: "cgstRate",
    //   headerName: "CGST-Rate",
    //   width: 100,
    //   valueGetter: (item) => item.row.cgstRate + "%",
    // },
    // { field: "cgstAmt", headerName: "CGST-Amt", width: 100 },
    // {
    //   field: "sgstRate",
    //   headerName: "SGST-Rate",
    //   width: 100,
    //   valueGetter: (item) => item.row.sgstRate + "%",
    // },
    // { field: "sgstAmt", headerName: "SGST-Amt", width: 100 },
    // {
    //   field: "igstRate",
    //   headerName: "IGST-Rate",
    //   width: 100,
    //   valueGetter: (item) => item.row.igstRate + "%",
    // },
    // { field: "igstAmt", headerName: "IGST-Amt", width: 100 },
    // { field: "netAmt", headerName: "NET-Amt", width: 100 },

    // { field: "isAmtEditable", headerName: "AmtEditable", width: 120 },
    // { field: "isActive", headerName: "Active", width: 100 },
    {
      field: "isAmtEditable",
      headerName: "Is-AmtEditable",
      width: 140,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <Controls.Checkbox
            label={curObj.row.isAmtEditable.toString()}
            name="isAmtEditable"
            value={curObj.row.isAmtEditable}
            onChange={(e) => isAmtEditable_Changed(e, curObj.row)}
          />
        );
      },
    },

    {
      field: "isActive",
      headerName: "Is-Active",
      width: 100,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <Controls.Checkbox
            label={curObj.row.isActive.toString()}
            name="isActive"
            value={curObj.row.isActive}
            onChange={(e) => isActive_Changed(e, curObj.row)}
          />
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton color="primary" title="Edit" onClick={() => openInEdit(curObj.row)}>
                <ModeEditOutlineIcon />
              </Controls.ActionButton>
              <Controls.ActionButton color="red" title="Delete" onClick={() => handleClickDelete(curObj.row)}>
                <CloseIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
  ];

  // const checkKeysUnderObject = (obj, result) => {
  //   for (let key in obj) {
  //     if (key) { // push the value to the result array only if key exists
  //       console.log("key + obj[key]  ", key + ": " + obj[key])
  //       result.push(key + ": " + obj[key]);
  //     }
  //   }
  // };

  const handleChangeAmount = (e) => {
    const { name, value } = e.target;

    if ("rate" === name) {
      setFormValues((preValue) => ({ ...preValue, rate: value }));
      setFormValues((preValue) => ({ ...preValue, amount: value }));
      setFormValues((preValue) => ({ ...preValue, taxableAmt: value }));
    }
    if ("igstRate" === name) {
      setFormValues((preValue) => ({ ...preValue, igstRate: value }));
    }
    if ("cgstRate" === name) {
      setFormValues((preValue) => ({ ...preValue, cgstRate: e.target.value }));
      setFormValues((preValue) => ({
        ...preValue,
        cgstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(e.target.value)) / 100).toFixed(2),
      }));
    }
    if ("sgstRate" === name) {
      setFormValues((preValue) => ({ ...preValue, sgstRate: e.target.value }));
      setFormValues((preValue) => ({
        ...preValue,
        sgstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(e.target.value)) / 100).toFixed(2),
      }));
    }

    setFormValues((preValue) => ({
      ...preValue,
      cgstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(preValue.cgstRate)) / 100).toFixed(2),
    }));
    setFormValues((preValue) => ({
      ...preValue,
      sgstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(preValue.sgstRate)) / 100).toFixed(2),
    }));

    setFormValues((preValue) => ({
      ...preValue,
      igstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(preValue.igstRate)) / 100).toFixed(2),
    }));
    setFormValues((preValue) => ({
      ...preValue,
      netAmt: (parseFloat(preValue.taxableAmt) + parseFloat(preValue.igstAmt)).toFixed(2),
    }));
  };

  // const handleChangeAmount = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues((preValue) => ({ ...preValue, [name]: value }));
  // };

  return (
    <div>
      <h2>Add Fee Head Sub</h2>
      <MyForm onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* feeHeadMain */}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Controls.DropdownList
              label="Fee Head Main"
              name="feeHeadMain_id"
              value={feeHeadMain_id}
              onChange={handleChange_FeeHeadMain}
              options={mainFeeHeadList}
              error={errors.feeHeadMain}
            />
          </Grid>

          {/* feeHead */}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Controls.Input
              label="FeeHead"
              name="feeHead"
              value={formValues.feeHead}
              onChange={handleChange}
              error={errors.feeHead}
            />
          </Grid>

          {/* rate */}
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Controls.Input
              type="number"
              label="Rate"
              name="rate"
              value={formValues.rate}
              onChange={handleChangeAmount}
              error={errors.rate}
            />
          </Grid>

          {/* qty     default:1 */}

          {/* amount */}
          {/* <Grid item xs={6} sm={4} md={4} lg={2}>
            <Controls.Input
              // type="number"
              label="Amount Rs."
              name="amount"
              value={formValues.amount}
              // onChange={handleChangeAmount}
              // error={errors.amount}
            />
          </Grid> */}

          {/* taxableAmt: 0, */}
          {/* <Grid item xs={6} sm={4} md={4} lg={2}>
            <Controls.Input
              // type="number"
              label="Taxable-Amt"
              name="taxableAmt"
              value={formValues.taxableAmt}
              // onChange={handleChangeAmount}
              // error={errors.taxableAmt}
            />
          </Grid> */}

          {/* cgstRate: 0, */}
          {/* <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.DropdownList
              label="CGST-Rate"
              name="cgstRate"
              value={formValues.cgstRate}
              onChange={handleChangeAmount}
              options={GenServices.getGSTRate()}
              error={errors.cgstRate}
            />
          </Grid> */}

          {/* cgstAmt: 0, */}
          {/* <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Input
              label="CGST-Amt"
              name="cgstAmt"
              value={formValues.cgstAmt}
              // onChange={handleChangeAmount}
              error={errors.cgstAmt}
            />
          </Grid> */}

          {/* sgstRate: 0, */}
          {/* <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.DropdownList
              label="SGST-Rate"
              name="sgstRate"
              value={formValues.sgstRate}
              onChange={handleChangeAmount}
              options={GenServices.getGSTRate()}
              error={errors.sgstRate}
            />
          </Grid> */}

          {/* sgstAmt: 0, */}
          {/* <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Input
              label="SGST-Amt"
              name="sgstAmt"
              value={formValues.sgstAmt}
              // onChange={handleChangeAmount}
              error={errors.sgstAmt}
            />
          </Grid> */}

          {/* igstRate: 0, */}
          {/* <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.DropdownList
              label="IGST-Rate"
              name="igstRate"
              value={formValues.igstRate}
              onChange={handleChangeAmount}
              options={GenServices.getGSTRate()}
              error={errors.igstRate}
            />
          </Grid> */}

          {/* igstAmt: 0, */}
          {/* <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Input
              label="IGST-Amt"
              name="igstAmt"
              value={formValues.igstAmt}
              // onChange={handleChangeAmount}
              error={errors.igstAmt}
            />
          </Grid> */}

          {/* netAmt: 0, */}
          {/* <Grid item xs={6} sm={4} md={4} lg={2}>
            <Controls.Input
              // type="number"
              label="Net-Amount"
              name="netAmt"
              value={formValues.netAmt}
              // onChange={handleChangeAmount}
              error={errors.netAmt}
            />
          </Grid> */}

          {/* remarks: "", */}
          {/* <Grid item xs={6} sm={4} md={4} lg={4}>
            <Controls.Input
              label="Remarks"
              name="remarks"
              value={formValues.remarks}
              onChange={handleChange}
              error={errors.remarks}
            />
          </Grid> */}

          {/* isAmtEditable */}
          {/* <Grid item xs={6} sm={4} md={4} lg={3}>
            <Controls.Checkbox
              label="Is-Amount-Editable"
              name="isAmtEditable"
              value={formValues.isAmtEditable}
              onChange={handleChange}
              // error={errors.isAmtEditable}
            />
          </Grid> */}

          {/* isActive */}
          {/* <Grid item xs={6} sm={4} md={4} lg={2}>
            <Controls.Checkbox
              label="Is-Active"
              name="isActive"
              value={formValues.isActive}
              onChange={handleChange}
              // error={errors.isActive}
            />
          </Grid> */}

          {/* Button */}
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <Box style={{ textAlign: "left" }}>
              <Controls.Button
                sx={{ mx: "10px" }}
                text="Save"
                //onClick={() => console.log(formValues)}
                //variant="outlined"
                type="submit"
                startIcon={<SaveRoundedIcon />}
              />
              <Controls.Button
                text="Reset"
                onClick={resetForm}
                //variant="outlined"
                startIcon={<RestartAltIcon />}
                // color="default"
              />
            </Box>
          </Grid>

          {/* <Grid item sm={12}>
          <FormControlLabel control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}

          {/* <Grid item sm={12} md={3}>
          <FormGroup row>  
            
            <TextField label="Test-Name" variant="standard" placeholder="username" />
            </FormGroup>
        </Grid> */}
        </Grid>
      </MyForm>
      {/* </Box> */}
      <br></br>
      {/* <Container> */}
      <DataGrid
        heading="Fee-Head-Main"
        columns={columns}
        rows={selectedFeeHeads}
        // isPaging={false}
        pageSize={50}
        boxHeight={680}
      />
      {/* </Container> */}
      <br></br>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </div>
  );
}
