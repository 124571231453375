import React, { useState } from "react";
import Grid from "@mui/material/Grid";

export function MyUseForm(initialValues, validateOnChange = false, validate) {
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;    

    // console.log("e.target.name =====>",e.target.name, "   e.target.value ====>",e.target.value);

    let inputName = name.split(".");

    let obj = {};
    if (inputName.length > 1) {
      var z = {};
      var z2 = formValues[inputName[0]];

      inputName.forEach((val, idx) => {
        if (idx === inputName.length - 1) {
          z[val] = e.target.value; //single name without any dot
        } else if (idx > 0) {
          Object.assign(z, z2); /*{...z2 , [val]:{} }*/
          z[val] = {};
          z = z[val];
          z2 = z2[val];
        } else {
          z = { ...z2 };
          obj = z;
        }
      });
    } else obj = e.target.value;

    setFormValues({ ...formValues, [inputName[0]]: obj });
    //}

    // setFormValues({
    //   ...formValues,
    //   [name]: value,
    // });

    if (validateOnChange) {
      // console.log("inputName: obj", { [inputName[0]]: obj });
      validate({
        [inputName[0]]: obj,
      });
      //[name]: value
    }
  };

  // const resetForm = () => {
  //   setFormValues(initialValues);
  //   setErrors({});
  // };

  return {
    formValues,
    setFormValues,
    errors,
    setErrors,
    handleChange,
    // resetForm,
  };
}

export function MyForm(props) {
  const { children, ...other } = props;

  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
