import React from "react"; 
 
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment"; 
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"; 
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddLinkIcon from "@mui/icons-material/AddLink";
import PostAddIcon from "@mui/icons-material/PostAdd"; 
import BiotechIcon from '@mui/icons-material/Biotech';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';

import Home from "../pages/Home";
import Login from "../pages/Login/Login";
import Logout from "../pages/Login/Logout";
import Staff from "../pages/HRM/Staff";
// import StaffRoles from "../pages/HRM/StaffRoles";
import UserSecurity_Tabs from "../pages/Admin/UserSecurity_Tabs";
import SystemParameter_Tabs from "../pages/Admin/SystemParameter_Tabs";
import FeeMaster_Tabs from "../pages/Billing/FeeMaster_Tabs";
import LabMaster_Tabs from "../pages/Lab/LabMaster_Tabs";
import AddLabTest_Tabs from "../pages/Lab/AddLabTest_Tabs";

import Regn_Report from "../pages/Regn/RegnReport/Regn_Report";
import OPD_Report from "../pages/Regn/RegnReport/OPD_Report";

import Registration from "../pages/Regn/Registration";
import OPDBilling from "../pages/Billing/OPDBilling";
// import PageFooter from "./components/PageFooter";

import OpdRegn from "../pages/Regn/OpdRegn";
import ErrorPage from "../pages/Error/ErrorPage";

import EntityMaster_Tabs from "../pages/Master/EntityMaster_Tabs";
import BillingReport from "../pages/Billing/FeeReport/BillingReport";
import LogsMaster_Tabs from "../pages/Admin/Logs/LogsMaster_Tabs"
// import Layout from "../components/Layout";

//Below data for Menus and Routes:-
const Navigation = [
  { id: 1, name: "Login", path: "/login", element: <Login />, menuIcon: "", isMenu: false, isPrivate: false, group: 0 },
  { id: 2, name: "Logout", path: "/logout", element: <Logout />, menuIcon: "", isMenu: false, isPrivate: false, group: 0 },

  { id: 3, name: "Home Page", path: "/", element: <Home />, menuIcon: <DashboardIcon />, isMenu: true, isPrivate: true, group: 1, },
  { id: 4, name: "Registration", path: "/regn", element: <Registration />, menuIcon: <PersonAddAlt1Icon />, isMenu: true, isPrivate: true, group: 1, },
  { id: 5, name: "OPD", path: "/opd", element: <OpdRegn />, menuIcon: <LocalHospitalIcon />, isMenu: true, isPrivate: true, group: 1, },
  { id: 6, name: "Billing-Service", path: "/opdbilling", element: <OPDBilling />, menuIcon: <CurrencyRupeeIcon />, isMenu: true, isPrivate: true, group: 1, },
  { id: 7, name: "Add Staff", path: "/staff", element: <Staff />, menuIcon: <PersonAddIcon />, isMenu: true, isPrivate: true, group: 1, },
  { id: 8, name: "User-Security-Mgt.", path: "/usersecurity", element: <UserSecurity_Tabs />, menuIcon: <ManageAccountsIcon />, isMenu: true, isPrivate: true, group: 1, },
  
  { id: 9, name: "Add Lab Test", path: "/addlabtest", element: <AddLabTest_Tabs />, menuIcon: <BiotechIcon />, isMenu: true, isPrivate: true, group: 1, },
  
  { id: 10, name: "System Parameter", path: "/systemparameter", element: <SystemParameter_Tabs />, menuIcon: <SettingsIcon />, isMenu: true, isPrivate: true, group: 1, },
  { id: 11, name: "Entity-Master", path: "/entity-master-tabs", element: <EntityMaster_Tabs />, menuIcon: <AddLinkIcon />, isMenu: true, isPrivate: true, group: 1, },
 
  { id: 101, name: "Regn. Report", path: "/regnreport", element: <Regn_Report />, menuIcon: <AssignmentIcon />, isMenu: true, isPrivate: true, group: 2, },
  { id: 102, name: "OPD Report", path: "/opdreport", element: <OPD_Report />, menuIcon: <AssignmentIcon />, isMenu: true, isPrivate: true, group: 2, },
  { id: 103, name: "Billing Report", path: "/billingreport", element: <BillingReport />, menuIcon: <AssignmentIcon />, isMenu: true, isPrivate: true, group: 2, },
 
    //SUPERADMIN
  { id: 501, name: "Fee Master", path: "/feemaster", element: <FeeMaster_Tabs />, menuIcon: <PostAddIcon />, isMenu: true, isPrivate: true, group: 1, },
  { id: 502, name: "Lab Master", path: "/labmaster", element: <LabMaster_Tabs />, menuIcon: <PostAddIcon />, isMenu: true, isPrivate: true, group: 1, },
  { id: 503, name: "Log-Master", path: "/log-master-tabs", element: <LogsMaster_Tabs />, menuIcon: <AddLinkIcon />, isMenu: true, isPrivate: true, group: 1, },

    
  // {id: 999, name: "Not-Found", path: "*", element: <ErrorPage />, menuIcon: "", isMenu: false, isPrivate: false, group: 1 },
  // { id: , name:"", path: "/", element: </>, menuIcon: </>,  isMenu: true, isPrivate: true, group: 2},
];

// const MenuRight =  {
//   RECEPTIONIST: [3,4,5,6,101,102,103,999],
//   LABORATORY: [3,9,999]  ,
//   ADMIN: [3,4,5,6,7,8,9,101,102,103,999],
//   // {SUPERADMIN: []  },
// }

const MenuRight =  {
  RECEPTIONIST: [3,4,5,6,101,102,103],
  LABORATORY: [3,9]  ,
  ADMIN: [3,4,5,6,7,8,9,10,11,101,102,103],
  // {SUPERADMIN: []  },
}


export default Navigation;
export  {MenuRight};
