import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";
// import RegistrationForm from "./RegistrationForm";
import PageHeader from "./../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
// import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import Checkbox from "@mui/material/Checkbox";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

import { InputAdornment } from "@mui/material";
import { MyUseForm, MyForm } from "../../components/useForm";

import * as GenServices from "../../helper/GenServices";
import * as RegnServices from "../../helper/RegnServices";
import { Toolbar } from "@mui/material";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";

import { useSelector, useDispatch } from "react-redux";
import { setAllOriginalFeeHeads, setAllFeeHeadsMasterList } from "../../redux/actions";
import { useGlobalContext } from "../../context/Context";

const initialValuesRegn = {
  // _id: "",
  mrNo: "",
  opdNo: "",
  name: "",
  soDo: "S/o.",
  fhName: "",
  gender: "",
  age: 0,
  pMobNo: "",
  // mobileNo: { pMobNo: "" },
  email: "",
  remarks: "",
  zeroBilling: false,
};

// const initialValuesForSubmit = {
//   hid: "2",
//   ref_reg_opd: "",
//   billNo: "",
//   billDate: null,
//   mrNo: 0,
//   opdNo: "",
//   paidRs: 0,
//   paymentMode: "Cash",
//   paymentDetail: "",
//   status: "Paid",
//   billDetail: [],
//   staffUN: "",
//   ip: "",
// };

const initialValuesForSubmit = {
  _id: "",
  ref_reg_opd: "",
  mrNo: 0,
  opdNo: "",
  iopNo: "",
  name: "",
  age: "",
  gender: "",
  pMobNo: "",
  email: "",
  billNo: "",
  beforeName: "",
  beforePMobNo: "",
};

export default function BillUpdate() {
  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();
  // const dispatch = useDispatch();
  const [hid] = useState(2);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  //Top panel
  const [mrNoValue, setMRNoValue] = useState("");
  const [regnValues, setRegnValues] = useState(initialValuesRegn);
  const [reportDataForRightTable, setreportDataForRightTable] = useState([]);

  //Bottom Panel
  const [mrNoValueOPD, setMRNoValueOPD] = useState("");
  const [opdData, setOpdData] = useState([]);

  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });
  const [errors, setErrors] = useState({});

  const [finalDataForSubmit, setFinalDataForSubmit] = useState(initialValuesForSubmit);

  const bindData = () => {
    // getOPDBillingRecord();
    getBillListByMRNo_Old();
  };

  // const handleByRightTable = (curItem) => {
  //   setRegnValues({ ...regnValues, opdNo: curItem.opdNo });
  // };

  const resetForm = () => {
    // setFormValues(initialValuesOpd);
    setDisabled(false);
    setMRNoValue("");
    setRegnValues(initialValuesRegn);
    // setRecordForEdit(null);
    // setErrors({});
    setNotify({ isOpen: false, message: "", type: "" });
    // cancelSearch();
    setLoading(false);
    setreportDataForRightTable([]);
    setFinalDataForSubmit(initialValuesForSubmit);

    setMRNoValueOPD("");
    setOpdData([]);

    setDisabledSubmitBtn(false);
    // setMRNoValueForReport("");
  };

  const getBillListByMRNo_Old = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      if (mrNoValue) {
        // const res = await axios.get(`/fee/opdbill/opdvisitlist/${mrNoValue}`);
        const res = await axios.get(`/fee/opdbill/getallopdbillbymrno/?hid=${hid}&mrNo=${mrNoValue}`);
        // console.log("getBillListByMRNo_Old  ===>", res.data);
        if (res) {
          setreportDataForRightTable(res.data);
          // setDataForFinal(res.data);
          // console.log("res.data ===>", res.data)
        }
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("Error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const getDataByMRNo = async () => {
    try {
      // if (recordForEdit === null) {
      if (mrNoValue) {
        const res = await axios.get(`/fee/opdbill/opddatabymrno/?hid=${hid}&mrNo=${mrNoValue}`);
        if (res.data) {
          // console.log("getDataByMRNo  ========> ", data);
          setRegnValues(res.data);

          await getBillListByMRNo_Old();
        } else {
          setNotify({ isOpen: true, message: "Data not found!", type: "error" });
        }
      } else {
        setNotify({ isOpen: true, message: "Please enter the MRNo!", type: "error" });
      }
      // } else {
      //   setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
      // }
    } catch (error) {
      console.error("getDataByMRNo Error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const getDataByMRNoOPD = async () => {
    try {
      // if (recordForEdit === null) {
      if (mrNoValueOPD) {
        if (mrNoValueOPD != mrNoValue) {
          const res = await axios.get(`/opd/?hid=${hid}&mrNo=${mrNoValueOPD}`);
          if (res.data) {
            // console.log("setreportDataForRightTable  ========> ", res.data);
            setOpdData(res.data);
          } else {
            setNotify({ isOpen: true, message: "Data not found!", type: "error" });
          }
        } else {
          setNotify({ isOpen: true, message: "Please enter the correct MRNo!", type: "error" });
        }
      } else {
        setNotify({ isOpen: true, message: "Please enter the MRNo!", type: "error" });
      }
      // } else {
      //   setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
      // }
    } catch (error) {
      console.error("getDataByMRNo Error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const handleChangeMRNo = (e) => {
    // if (recordForEdit === null) {
    setErrors({});
    setMRNoValue(e.target.value);
    setRegnValues(initialValuesRegn);
    // cancelSearch();
    setLoading(false);
    setreportDataForRightTable([]);
    setOpdData([]);
  };

  const handleChangeMRNoForOPD = (e) => {
    if (disabled) {
      setErrors({});
      setMRNoValueOPD(e.target.value);
    } else {
      setNotify({ isOpen: true, message: "Please select the BillNo from above table!", type: "error" });
    }
  };

  const handleBillNoSelect = (e, data) => {
    // console.log("handleBillNoSelect ================>", data);
    setFinalDataForSubmit((preValue) => ({ ...preValue, _id: data._id }));
    setFinalDataForSubmit((preValue) => ({ ...preValue, billNo: data.billNo }));
    setFinalDataForSubmit((preValue) => ({ ...preValue, beforeName: data.name }));
    setFinalDataForSubmit((preValue) => ({ ...preValue, beforePMobNo: data.pMobNo }));
    setDisabled(true);
  };

  const handleOpdNoSelect = (e, data) => {
    console.log("handleOpdNoSelect ================>", data);
    if (e.target.value) {
      setFinalDataForSubmit((preValue) => ({ ...preValue, mrNo: data.mrNo }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, opdNo: data.opdNo }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, iopNo: data.opdNo }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, name: data.name }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, age: data.age }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, gender: data.gender }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, pMobNo: data.pMobNo }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, email: data.email }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, ref_reg_opd: data._id }));      
    } else {
      setFinalDataForSubmit((preValue) => ({ ...preValue, mrNo: "" }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, opdNo: "" }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, iopNo: "" }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, name: "" }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, age: "" }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, gender: "" }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, pMobNo: "" }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, email: "" }));
      setFinalDataForSubmit((preValue) => ({ ...preValue, ref_reg_opd: "" }));       
    }
  };

  useEffect(() => {
    console.log("finalDataForSubmit ================>", finalDataForSubmit);
  }, [finalDataForSubmit]);

  const columnsForRightTopTable = [
    {
      field: "actions",
      headerName: "Select",
      width: 60,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <Controls.Checkbox
            disabled={disabled}
            // label={curItem.row.isActive.toString()}
            name="select"
            // value={curItem.row.isActive}
            onChange={(e) => handleBillNoSelect(e, curItem.row)}
          />
        );
      },
    },
    { field: "mrNo", headerName: "MRNo", width: 80 },
    // { field: "billNo", headerName: "BillNo", width: 100 },
    {
      field: "billDate",
      headerName: "Bill Date/No.",
      width: 120,
      // valueGetter: (item) => dayjs(item.row.billDate).format("DD/MMM/YYYY hh:mm a"),
      renderCell: (item) => (
        <div>
          <Typography>{dayjs(item.row.billDate).format("DD/MM/YYYY")}</Typography>
          <Typography color="textSecondary">{item.row.billNo}</Typography>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name/FName",
      width: 150,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.fhName}
          </Typography>
        </div>
      ),
    },
    {
      field: "paidRs",
      headerName: "PaidRs",
      width: 80,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.paidRs}</Typography>
          <Typography color="textSecondary">{item.row.paymentMode}</Typography>
        </div>
      ),
    },
    { field: "feeHeadList", headerName: "Fee-Head-List", width: 350 },
  ];

  const columnsOpdForRightBottom = [
    {
      field: "actions",
      headerName: "Select",
      width: 60,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <Controls.Checkbox
            // disabled={disabled}
            // label={curItem.row.isActive.toString()}
            name="select"
            // value={curItem.row.isActive}
            onChange={(e) => handleOpdNoSelect(e, curItem.row)}
          />
        );
      },
    },
    { field: "mrNo", headerName: "MRNo.", width: 100 },
    { field: "opdNo", headerName: "OPD No.", width: 100 },
    {
      field: "opdDate",
      headerName: "OPD Date",
      width: 100,
      valueGetter: (item) => dayjs(item.row.opdDate).format("DD/MM/YYYY hh:mm a"),
    },
    {
      field: "name",
      headerName: "Name/FName",
      width: 150,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.fhName}
          </Typography>
        </div>
      ),
    },

    // { field: "consultant", headerName: "Consultant", width: 150 },
  ];

  const handleFinalSubmit = async () => {
    if (isEditRight) {
      setLoading(true);
      setDisabledSubmitBtn(true);

      if (finalDataForSubmit.mrNo && finalDataForSubmit.opdNo && finalDataForSubmit.name) {
        console.log("Final Submit ====>", finalDataForSubmit);
        const res = await axios.patch(
          `/fee/opdbill/replaceBillAndLabTest/?hid=${hid}&staffUN=${staffUN}&ip=${ip}`,
          finalDataForSubmit
        );
        if (res.data) {
          // console.log("setreportDataForRightTable  ========> ", res.data);
          // setOpdData(res.data);
          setLoading(false);
          setNotify({ isOpen: true, message: "Bill and Lab-Report Replaced Successfully", type: "success" });
        } else {
          setNotify({ isOpen: true, message: "Please try again!", type: "error" });
          setDisabledSubmitBtn(false);
        }
      } else {
        setNotify({ isOpen: true, message: "Please select the data for Updation!", type: "error" });
        setDisabledSubmitBtn(false);
      }
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to update!", type: "error" });
      setDisabledSubmitBtn(false);
    }
  };

  const handleSubmitCharges = async (e) => {
    // e.preventDefault();

    if (finalDataForSubmit) {
      try {
        // console.log("axios Insert Start===>", Staff);
        setLoading(true);
        const res = await axios.post("/fee/opdbill", finalDataForSubmit);
        // console.log("axios Insert response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Saved Successfully", type: "success" });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "Please add the Charges!",
        type: "error",
      });
    }
  };

  return (
    <>
      <PageHeader
        title="Bill Update/Replace"
        subTitle="SubTitle here"
        icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />}
      />

      <Box sx={{ mb: 5 }}>
        <h2>Bill and Lab Report Replace to (Old Patient)</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container spacing={2}>
              {/* mrNo */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="MRNo"
                  name="mrNo"
                  disabled={disabled}
                  value={mrNoValue}
                  onChange={handleChangeMRNo}
                  title="press tab key for get record"
                  onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
                  // error={errors.mrNo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton
                          onClick={getDataByMRNo}
                          disabled={disabled}
                          color="primary"
                          sx={{ p: "10px" }}
                          aria-label="directions"
                        >
                          <DirectionsIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  //       <IconButton onClick={getDataByMRNo} color="primary" sx={{ p: "10px" }} aria-label="directions">
                  //         <DirectionsIcon />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
              {/* opdNo */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="OPDNo"
                  name="opdNo"
                  value={regnValues.opdNo}
                  // onChange={handleChange}
                  error={errors.opdNo}
                  // style={{background: "rgb(232, 241, 250)"}}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Name */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Full Name"
                  name="name"
                  value={regnValues.name}
                  // onChange={handleChange}
                  error={errors.name}
                  // style={{background: "rgb(232, 241, 250)"}}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Father/MotherName */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Father/Husband Name"
                  name="fhName"
                  value={regnValues.fhName}
                  // onChange={handleChange}
                  error={errors.fhName}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{regnValues.soDo}</InputAdornment>,
                  }}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Gender/Age */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Gender/Age"
                  name="gender"
                  value={regnValues.gender + " / " + regnValues.age}
                  // onChange={handleChange}
                  // options={GenServices.getGender()}
                  // error={errors.gender}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* Email */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Email"
                  name="email"
                  value={regnValues.email}
                  // onChange={handleChange} error={errors.email}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* mobileNo.pMobNo */}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Mobile Number"
                  name="pMobNo"
                  value={regnValues.pMobNo}
                  // onChange={handleChange}
                  // error={errors.pMobNo}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>

              {/* remarks */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Remarks"
                  name="remarks"
                  value={regnValues.remarks}
                  // onChange={handleChange}
                  // error={errors.remarks}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>
              {/* **************************************************************************** */}
              <Divider sx={{ width: "100%", height: 10, m: 0.5 }} orientation="horizontal" />

              {/* </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {/* Patient Wise Bill List*/}
            <DataGrid
              // heading="Patient Wise Bill List" in the Right-Top Corner
              columns={columnsForRightTopTable}
              rows={reportDataForRightTable}
              showToolbar={false}
              margin="dense"
            />
          </Grid>
        </Grid>
      </Box>
      {/* ************************************************************************************************************* */}

      <Box>
        <h2>Bill and Lab Report Replace with (New Patient)</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {/* <Grid container spacing={2}> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                {/* mrNo */}
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Controls.Input
                    label="MRNo"
                    name="mrNo"
                    value={mrNoValueOPD}
                    onChange={handleChangeMRNoForOPD}
                    title="press tab key for get record"
                    onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNoOPD() : "")}
                    // error={errors.mrNo}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                          <IconButton
                            onClick={getDataByMRNoOPD}
                            color="primary"
                            sx={{ p: "10px" }}
                            aria-label="directions"
                          >
                            <DirectionsIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {/* opdNo */}
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Controls.Input
                    label="OPDNo"
                    name="opdNo"
                    value={finalDataForSubmit.opdNo}
                    // onChange={handleChange}
                    // error={errors.opdNo}
                    // style={{background: "rgb(232, 241, 250)"}}
                    // style={{ background: "#e9e9e9" }}
                  />
                </Grid>

                <Divider sx={{ width: "100%", height: 10, m: 0.5 }} orientation="horizontal" />

                {/* Button */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box m={1} display="flex">
                    <span>
                      <Controls.Button
                        text="Reset"
                        onClick={resetForm}
                        // variant="outlined"
                        //variant="outlined"
                        startIcon={<RestartAltIcon />}
                        // color="default"
                      />
                    </span>
                    <span style={{ mx: "10px", flexGrow: 1, textAlign: "center" }}>
                      <Controls.Button
                        text="Update"
                        onClick={handleFinalSubmit}
                        // variant="outlined"
                        startIcon={<AddIcon />}
                        disabled={disabledSubmitBtn}
                        sx={{ mr: "15px" }}
                        size="medium"
                      />
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <DataGrid
              // heading="Patient OPD Records for Right Table"
              loading={loading}
              columns={columnsOpdForRightBottom}
              rows={opdData}
              // showToolbar={false}
              pageSize={40}
              autoPageSize
              boxHeight={350}
            />
          </Grid>
        </Grid>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <box>Note: Only admin can replace the bill.</box>
    </>
  );
}
