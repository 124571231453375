import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";

import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Toolbar } from "@mui/material";

import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import { Container } from "@mui/system";

import * as BillingServices from "../../helper/BillingServices";
import * as LabServices from "../../helper/LabServices";

import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../components/useForm";

import * as GenServices from "../../helper/GenServices";

import { useSelector, useDispatch } from "react-redux";
import { addLabTestListMasterData } from "../../redux/actions";

//below code for Inner object of Array
const initialValues = {
  // _id: "",
  testName: "",
  //isNumber: true,
  normalValue: "",
  unit: "",
  isActive: true,
  columnNo: 1, //if report print in multiple column then it will be required;
  sorting: 1,
  staffUN: "",
  ip: "",
};

export default function LabTestList_Sub() {

  
  
  const [hid] = useState(2);
  //Note: In this page add or update the nested subHeadings only (ie. subHeadings:[{},{}])
  const dispatch = useDispatch();
  // const AllsubHeadings = useSelector((state) => state.subHeadingList_Reducer.mainHeading);

  const hook_AllTestList = useSelector((state) => state.LabTestList_Reducer.testData);

  // console.log("hook_AllTestList ==>", hook_AllTestList);
  // const hookData = useSelector((state) => {
  //   return {
  //     AllsubHeadings: state.subHeadingList_Reducer.mainHeading,
  //     //regHeadList:state.subHeadingList_Reducer.mainHeading
  //   };
  // });

  // const [mainHeadingList, setMainHeadingList] = useState([]);
  // const [mainHeading, setMainHeading] = useState("");

  const [selectedsubHeadings, setSelectedsubHeadings] = useState([]);
  const [bottomTableData, setBottomTableData] = useState([]);
  const [subHeadings, setSubHeadings] = useState("");
  // const [recordForEdit, setRecordForEdit] = useState(null);

  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const validate = (fieldValues = formValues) => {
    let temp = { ...errors };

    // console.log("fieldValues =====>", fieldValues.dob);
    // if ("mainHeading" in fieldValues) temp.mainHeading = fieldValues.mainHeading ? "" : "required.";

    if (
      subHeadings == "0" ||
      subHeadings.length < 3 ||
      subHeadings == "" ||
      subHeadings == null ||
      subHeadings == undefined
    ) {
      temp.subHeadings = "required.";
    } else {
      temp.subHeadings = "";
    }

    if ("testName" in fieldValues) temp.testName = fieldValues.testName ? "" : "required.";

    // if ("normalValue" in fieldValues) temp.normalValue = fieldValues.normalValue ? "" : "required.";

    // if ("unit" in fieldValues) temp.unit = fieldValues.unit ? "" : "required.";

    if ("columnNo" in fieldValues) temp.columnNo = fieldValues.columnNo !== "0" ? "" : "required.";

    if ("sorting" in fieldValues) temp.sorting = parseInt(fieldValues.sorting) >= 0 ? "" : "required.";

    setErrors({
      ...temp,
    });
    // console.log("temp===>", temp);
    if ((fieldValues = formValues)) return Object.values(temp).every((x) => x == "");
  };

  const { formValues, setFormValues, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getRecord = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      // const res = await axios.get(`/lab/testlist/subheads/?hid=${hid}`); //getAllSubHeads <= here Get All MainHead + Nested(SubHead)
      const res = await axios.get(`/lab/testlist/?hid=${hid}`); //getAllSubHeads <= here Get All MainHead + Nested(SubHead)
      // console.log("getRecord by subHeadingSub  ===>", res);
      // const res = await LabServices.GetLabTestList();
      if (res) {
        dispatch(addLabTestListMasterData(res.data));
        // setRecords(res.data);
        // dispatch(addLabTestListMasterData(res.data));
        // dispatch(addLabTestListMasterData(res));
        // console.log("res.data 1111111 ===>", res.data)
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getRecord();
    //getMainHeads();
    // console.log("formValues Data ==>", formValues);
  }, []);

  // useEffect(() => {
  //   console.log("formValues Data ==>", formValues);
  //   // console.log("subHeadings Data ==>", subHeadings);
  // }, [formValues]);

  useEffect(() => {
    // console.log("fhook_AllTestList ==>", hook_AllTestList);
    // BindMainTestList();
    BindSubTestList();
    BindBottomTable();
  }, [hook_AllTestList]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      // console.log("validate ===>", errors);
      // alert('fsdfsdfdsfsf');
      addOrEdit(formValues);
    } else {
      setNotify({
        isOpen: true,
        message: "Please enter the value in required fields!",
        type: "error",
      });
      //  console.log(errors)
    }
  };

  const resetForm = () => {
    setFormValues(initialValues);
    // setRegnValues(initialValuesRegn);
    // setRecordForEdit(null);
    setErrors({});
    setNotify({ isOpen: false, message: "", type: "" });
    bindData();
    // setMRNoValueForReport("");
  };

  const addOrEdit = async (data) => {
    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
    if (subHeadings) {
      //This is for Update and Push new Value in subHeadings Nested Array
      try {
        // console.log("axios.patch data to be send ===>", data);
        // return
        // const ddd = { subHeadings: [data]};
        // const res = await axios.patch(`/lab/testlist/${mainHeading}`, ddd); //, queryobj);
        const res = await axios.patch(`/lab/testlist/nestedhead/${subHeadings.val}`, data); //, queryobj);
        // console.log("axios.patch response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Updated Successfully", type: "success" });
          if (data._id) setFormValues(initialValues);
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    }
  };

  const onDelete = async (id) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      console.log("axios.patch onDelete ===>", id);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.patch(`/lab/testlist/removenestedhead/${subHeadings.val}`, { _id: id }); //, queryobj);
      // const res = await axios.delete(`/lab/testlist/removehead/${id}`);
      if (res) {
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        await getRecord();
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    // RegnServices.deletePatient(id);
  };

  const handleClickDelete = (nestedData) => {
    //if (window.confirm("Do you want to delete this record?")) {
    // console.log("data.row._id", data)
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subtitle: "You can't undo this operation",
      isConfirm: () => onDelete(nestedData._id),
    });
  };

  const openInEdit = (data) => {
    // console.log("data  openInEdit", data);
    setFormValues(data);
  };

  const bindData = () => {
    // await hookData();
    setErrors({});
    // resetForm();
    // setRecordForEdit(null);
    // setFormValues(initialValues);
    getRecord();
  };

  // const handleChange_FeeSubHeading = (e) => {
  //   // const { name, value } = e.target;
  //   if (e.target.value != null && e.target.value != "") {
  //     setFormValues((preValue) => ({ ...preValue, ref_fee_head_list: e.target.value.val }));
  //   }
  //   //BindBottomTable(value);
  //   // BindSubTestList(e.target.value);
  // };

  const handleChange_TestSubHeading = (e) => {
    const { name, value } = e.target;
    // console.log("handleChange_TestSubHeading=== ", value);
    setSubHeadings(value);
    // setErrors((preValue) => ({ ...preValue, subHeadings: "" }));
    setErrors({});
    // setFormValues(initialValues);
    // BindBottomTable(value); errors.subHeadings
  };

  // const BindMainTestList = async () => {
  //   // console.log("BindBottomTable Value========>", val);
  //   setMainHeadingList([]);
  //   // const originalSelectedRows = useSelector((state) => state.subHeadingList_Reducer.mainHeading);
  //   const originalSelectedRows = await hook_AllTestList;
  //   const filteredRows2 = await originalSelectedRows.map((item) => {
  //     return { val: item.mainHeading, text: item.mainHeading };
  //   });
  //   // console.log("BindMainTestList =>", filteredRows2);
  //   //const filteredRows3 = await filteredRows2.slice().reverse();
  //   setMainHeadingList(filteredRows2);
  // };

  const BindSubTestList = async () => {
    //console.log("BindSubTestList Value========>", val);
    //if (val) {
    setSelectedsubHeadings([]);

    // const originalSelectedRows = useSelector((state) => state.subHeadingList_Reducer.mainHeading);
    const originalSelectedRows = await hook_AllTestList;
    // console.log("hook_AllTestList BindSubTestList =>", originalSelectedRows);

    // const filteredRows = await originalSelectedRows.filter((row) => {
    //   return row.mainHeading === val;
    // });

    // console.log("hook_AllTestList filteredRows =>", filteredRows);

    //const filteredRows2 = await filteredRows[0].subHeadings;

    const filteredRows2 = await originalSelectedRows.map((item) => {
      return { val: item._id, text: item.subHeading };
    });

    // console.log("filteredRows2 =>", filteredRows2);
    //const filteredRows3 = await filteredRows2.slice().reverse();
    setSelectedsubHeadings(filteredRows2);
  };

  useEffect(() => {
    // console.log("fhook_AllTestList ==>", hook_AllTestList);
    // BindMainTestList();
    BindBottomTable();
  }, [subHeadings]);

  const BindBottomTable = async () => {
    //console.log("BindSubTestList Value========>", val);
    if (subHeadings) {
      setBottomTableData([]);

      // const originalSelectedRows = useSelector((state) => state.subHeadingList_Reducer.mainHeading);
      const originalSelectedRows = await hook_AllTestList;

      // console.log("AAAAAAAAAAAA subHeadings =>", subHeadings);

      // console.log("AAAAAAAAAAAA subHeadings.val =>", subHeadings.val);

      const filteredRows = await originalSelectedRows.filter((row) => {
        return row._id === subHeadings.val;
      });

      // console.log("BBBBBBBBBBBB filteredRows =>", filteredRows);

      const filteredRows2 = await filteredRows[0].testList;
      //const filteredRows3 = await filteredRows2.slice().reverse();
      setBottomTableData(filteredRows2);
    }
  };

  const checkbox_Changed = async (e, data) => {
    // console.log("data checkbox_Changed =========>", data);
    data.isActive = e.target.value;

    try {
      // const ddd = { feeHeads: [data]};
      // const res = await axios.patch(`/lab/testlist/${data._id}`, data); //, queryobj);
      const res = await axios.patch("/lab/testlist/update_nestedlabtestlist", data); //, queryobj);
      // console.log("axios.patch response===>", res);
      if (res) {
        //bindData();
        getRecord();
        setNotify({ isOpen: true, message: "Update Successfully", type: "success" });
        // if (data._id) setFormValues(initialValues);
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
  };

  const processRowUpdate = React.useCallback(
    (newRow, oldRow) =>
      new Promise(async (resolve, reject) => {
        try {
          // const mutation = computeMutation(newRow, oldRow);
          // console.log("oldRow ===>", oldRow);
          // console.log("newRow ===>", newRow);
          const isUpdated = await axios.patch("/lab/testlist/update_nestedlabtestlist", newRow); //, queryobj);
          // console.log("isUpdated ===>", isUpdated);
          if (isUpdated.data) {
            // Save the arguments to resolve or reject the promise later
            // setPromiseArguments({ resolve, reject, newRow, oldRow });
            await getRecord();
            resolve(newRow);
            setNotify({ isOpen: true, message: "Update Successfully", type: "success" });
          } else {
            resolve(oldRow); // Nothing was changed
          }
        } catch (error) {
          resolve(oldRow);
          console.error("error=====>", error);
          setNotify({ isOpen: true, message: error.message, type: "error" });
        }
      }),
    []
  );

  const columns = [
    // {
    //   field: "subHeading",
    //   headerName: "Fee-Head",
    //   width: 200,
    //   valueGetter: (item) => item.row.subHeadings.subHeading,
    // },
    {
      field: "isActive",
      headerName: "Is-Active",
      width: 100,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.Checkbox
                label={curObj.row.isActive.toString()}
                name="isActive"
                value={curObj.row.isActive}
                // onChange={(e) => checkbox_Changed(e, curObj.row)}
              />
            </Stack>
          </>
        );
      },
    },
    // { field: "isActive", headerName: "Active", width: 100 },
    { field: "testName", headerName: "Test-Name", width: 200, editable: true },
    { field: "normalValue", headerName: "Normal-Value", width: 150, editable: true },
    { field: "unit", headerName: "Unit", width: 150, editable: true },

    { field: "columnNo", headerName: "Column-No.", type: "number", width: 100, editable: true },
    { field: "sorting", headerName: "Sorting", type: "number", width: 100, editable: true },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton color="primary" title="Edit" onClick={() => openInEdit(curObj.row)}>
                <ModeEditOutlineIcon />
              </Controls.ActionButton>
              <Controls.ActionButton color="red" title="Delete" onClick={() => handleClickDelete(curObj.row)}>
                <CloseIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
  ];

  // const checkKeysUnderObject = (obj, result) => {
  //   for (let key in obj) {
  //     if (key) { // push the value to the result array only if key exists
  //       console.log("key + obj[key]  ", key + ": " + obj[key])
  //       result.push(key + ": " + obj[key]);
  //     }
  //   }
  // };

  const handleChangeAmount = (e) => {
    const { name, value } = e.target;

    if ("rate" === name) {
      setFormValues((preValue) => ({ ...preValue, rate: value }));
      setFormValues((preValue) => ({ ...preValue, amount: value }));
      setFormValues((preValue) => ({ ...preValue, taxableAmt: value }));
    }
    if ("igstRate" === name) {
      setFormValues((preValue) => ({ ...preValue, igstRate: value }));
    }
    if ("cgstRate" === name) {
      setFormValues((preValue) => ({ ...preValue, cgstRate: e.target.value }));
      setFormValues((preValue) => ({
        ...preValue,
        cgstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(e.target.value)) / 100).toFixed(2),
      }));
    }
    if ("sgstRate" === name) {
      setFormValues((preValue) => ({ ...preValue, sgstRate: e.target.value }));
      setFormValues((preValue) => ({
        ...preValue,
        sgstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(e.target.value)) / 100).toFixed(2),
      }));
    }

    setFormValues((preValue) => ({
      ...preValue,
      cgstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(preValue.cgstRate)) / 100).toFixed(2),
    }));
    setFormValues((preValue) => ({
      ...preValue,
      sgstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(preValue.sgstRate)) / 100).toFixed(2),
    }));

    setFormValues((preValue) => ({
      ...preValue,
      igstAmt: ((parseFloat(preValue.taxableAmt) * parseFloat(preValue.igstRate)) / 100).toFixed(2),
    }));
    setFormValues((preValue) => ({
      ...preValue,
      netAmt: (parseFloat(preValue.taxableAmt) + parseFloat(preValue.igstAmt)).toFixed(2),
    }));
  };

  // const handleChangeAmount = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues((preValue) => ({ ...preValue, [name]: value }));
  // };

  //const mainHeadingList = ;
  // console.log(mainHeadingList)

  return (
    <div>
      <h2>Add Lab Test (Nested)</h2>
      <MyForm onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* subHeading of TestList */}
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Controls.Autocomplete
              label="Test-Sub-Heading"
              name="subHeading"
              // value={formValues.subHeading}
              onChange={handleChange_TestSubHeading}
              options={selectedsubHeadings}
              error={errors.subHeadings}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>

          {/* testName */}
          <Grid item xs={6} sm={4} md={4} lg={4}>
            <Controls.Input
              label="Test-Name"
              name="testName"
              value={formValues.testName}
              onChange={handleChange}
              error={errors.testName}
            />
          </Grid>

          {/* normalValue */}
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <Controls.Input
              // type="number"
              label="Normal-Value-Range"
              name="normalValue"
              value={formValues.normalValue}
              onChange={handleChange}
              error={errors.normalValue}
            />
          </Grid>
          {/* unit */}
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <Controls.Autocomplete
              // type="number"
              label="Unit"
              name="unit"
              value={formValues.unit}
              onChange={handleChange}
              options={LabServices.getLabUnitName()}
              error={errors.unit}
            />
          </Grid>
          {/* columnNo */}
          <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.DropdownList
              label="Column-No"
              name="columnNo"
              value={formValues.columnNo}
              onChange={handleChange}
              options={LabServices.getColumnNumber()}
              error={errors.columnNo}
              title="if report print in multiple column then it will be required"
            />
          </Grid>

          {/* sorting: */}
          <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controls.Input
              type="number"
              label="Sorting"
              name="sorting"
              value={formValues.sorting}
              onChange={handleChange}
              error={errors.sorting}
            />
          </Grid>
          {/* isActive */}
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <Controls.Checkbox
              label="Is-Active"
              name="isActive"
              value={formValues.isActive}
              onChange={handleChange}
              // error={errors.isActive}
            />
          </Grid>

          {/* Button */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box style={{ textAlign: "center" }}>
              <Controls.Button
                sx={{ mx: "10px" }}
                text="Save"
                //onClick={() => console.log(formValues)}
                //variant="outlined"
                type="submit"
                startIcon={<SaveRoundedIcon />}
              />
              <Controls.Button
                text="Reset"
                onClick={resetForm}
                //variant="outlined"
                startIcon={<RestartAltIcon />}
                // color="default"
              />
            </Box>
          </Grid>

          {/* <Grid item sm={12}>
          <FormControlLabel control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}

          {/* <Grid item sm={12} md={3}>
          <FormGroup row>  
            
            <TextField label="Test-Name" variant="standard" placeholder="username" />
            </FormGroup>
        </Grid> */}
        </Grid>
      </MyForm>
      {/* </Box> */}
      <br></br>
      {/* <Container> */}
      <DataGrid
        heading="Lab-Inner-Test-List"
        columns={columns}
        rows={bottomTableData}
        pageSize={50}
        boxHeight={680}
        processRowUpdate={processRowUpdate}
      />
      {/* </Container> */}
      <br></br>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <div>Double click on cell for Edit and update</div>
    </div>
  );
}
