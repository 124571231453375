import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";
import RegistrationForm from "./RegistrationForm";
import PageHeader from "./../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Divider from "@mui/material/Divider";
import { Typography, InputAdornment, Box, Paper, TableContainer } from "@mui/material";

import * as RegnServices from "../../helper/RegnServices";
import { Toolbar } from "@mui/material";
import { useGlobalContext } from "../../context/Context";

export default function Registration() {
  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();

  // const data = getAllPatients();
  const [hid] = useState(2);

  const [originalReportData, setOriginalReportData] = useState([]);
  const [records, setRecords] = useState([]);
  // const [searchReportData, setsearchReportData] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const [isSaved, setIsSaved] = React.useState(false);
  // const [selectedValue, setSelectedValue] = React.useState({});
  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const getAllPatients = async () => {
    setLoading(true);
    try {
      // const res = await axios.get("/regn");
      const res = await axios.get(`/regn/report/?hid=${hid}&startdate=${dayjs()}&enddate=${dayjs()}`);
      // console.log(res.data);
      if (res) {
        setRecords(res.data);
        setOriginalReportData(res.data);
        // cancelSearch();
        // console.log("getAllPatients ===>", res.data);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPatients();
  }, []);

  // useEffect(() => {
  //   getAllPatients();
  // }, [isSaved]);

  // const handleClickOpen = () => {
  //   setOpenPopup(true);
  // };

  // const handleClose = (value) => {
  //   setOpenPopup(false);
  //   setSelectedValue(value);
  // };

  // const resetForm = () => {
  //   setFormValues(initialValues);
  //   setErrors({});
  // };

  const bindData = async () => {
    // resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    await getAllPatients();
    //setRecords(result);
    // setIsSaved(true);
    setLoading(false);
  };

  const addOrEdit = async (Patient) => {
    // console.log("Patient addOrEdit =========>", Patient);
    Patient.staffUN = staffUN;
    Patient.ip = ip;

    if (Patient._id) {
      // RegnServices.updatePatient(Patient);
      try {
        // console.log("axios patch Start===>", Patient);
        const res = await axios.patch(`/regn/${Patient._id}/?hid=${hid}&mrno=${Patient.mrNo}`, Patient);
        console.log("axios.patch response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Updated Successfully", type: "success" });
        }
        //console.log("axios.patch  res ===>",res)
        //RegnServices.insertPatient(Patient);
      } catch (error) {
        console.error("error=====>", error);
        setLoading(false);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      try {
        // console.log("axios Insert Start===>", Patient);
        const res = await axios.post("/regn", Patient);
        // console.log("axios Insert response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Saved Successfully", type: "success" });
        }
        // console.log(res.data.hits);
        //setRecords(res.data.hits);
      } catch (error) {
        setLoading(false);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    }
  };

  const openInPopup = (data) => {
    //console.log("openInPopup====>", data.row);
    // if (isEditRight) {
      setRecordForEdit(data.row);
      setOpenPopup(true);
      // setIsSaved(false);
    // } else {
    //   setNotify({ isOpen: true, message: "you do not have the right to edit!", type: "error" });
    // }
  };

  const onDelete = async (id, mrNo) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      // console.log("axios.patch===>", Patient);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.delete(`/regn/${id}/?mrno=${mrNo}`);
      if (res) {
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        bindData();
      }
    } catch (error) {
      console.error("error=====>", error);
      let err = error.response.data +' / '+ error.message;
      setNotify({ isOpen: true, message: err , type: "error" });
    }
    // RegnServices.deletePatient(id);
  };

  const handleClickDelete = (data) => {
    if (isDeleteRight) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete this record?",
        subtitle: "You can't undo this operation",
        isConfirm: () => {
          onDelete(data.row._id, data.row.mrNo);
        },
      });
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to delete", type: "error" });
    }
  };

  //setRecordForEdit(curObj.row);
  //setOpenPopup(true);

  const columns = [
    //{ field: "regNo", headerName: "#", width: 90, filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
    // { field: "name", headerName: "Full name", width: 150, editable: true },
    // { field: "_id", headerName: "_id", width: 20, hideable: true },
    {
      field: "createdAt",
      headerName: "Regn Date",
      width: 170,
      valueGetter: (item) => dayjs(item.row.createdAt).format("DD/MM/YYYY hh:mm a"),
    },
    { field: "mrNo", headerName: "MRNo", width: 100 },
    { field: "regNo", headerName: "RegNo", width: 100 },
    { field: "name", headerName: "Full name", width: 200 },
    { field: "fhName", headerName: "F/H Name", width: 150 },
    // {
    //   field: "name",
    //   headerName: "Name/FName",
    //   width: 160,
    //   // valueGetter: (item) => item.row.name,
    //   renderCell: (item) => (
    //     <div>
    //       <Typography>{item.row.name}</Typography>
    //       <Typography color="textSecondary" sx={{ fontSize: 12 }}>
    //         {item.row.fhName}
    //       </Typography>
    //     </div>
    //   ),
    // },
    // { field: "age", headerName: "Age", type: "number", width: 80, valueGetter: (item) => item.row.age },
    // { field: "gender", headerName: "Gender", width: 80 },
    {
      field: "age",
      headerName: "Age/Sex",
      width: 120,
      // valueGetter: (item) => item.row.ref_reg_patient.age + "/" + item.row.ref_reg_patient.gender.slice(0, 1),
      // valueGetter: (item) => item.row.age + "/" + item.row.gender.slice(0, 1),
      renderCell: (item) => (
        <div>
          <Typography>{item.row.age + "/" + item.row.gender.slice(0, 1)}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 13 }}>
            {item.row.mobileNo.pMobNo}
          </Typography>
        </div>
      ),
    },
    // { field: "pMobNo", headerName: "Mobile No.", width: 120, valueGetter: (item) => item.row.mobileNo.pMobNo },

    // { field: "treatmentType", headerName: "treatmentType", width: 150, editable: true },
    // { field: "department", headerName: "department", width: 150, editable: true },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton color="primary" title="Edit" onClick={() => openInPopup(curItem)}>
                <ModeEditOutlineIcon />
              </Controls.ActionButton>
              <Controls.ActionButton color="red" title="Delete" onClick={() => handleClickDelete(curItem)}>
                <CloseIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
    {
      field: "Address",
      headerName: "Address",
      width: 250,
      // valueGetter: (item) => `${item.row.pAddress.address || ""}, ${item.row.pAddress.city || ""}`,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.pAddress.address}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.pAddress.city}
          </Typography>
        </div>
      ),
    },
  ];

  let filterTimeout;

  const handleSearch = async (e) => {
    try {
      clearTimeout(filterTimeout);
      const val = e.target.value;
      setSearchVal(val);

      if (val.length > 1) {
        filterTimeout = setTimeout(async () => {
          const res = await axios.get(`/regn/searchpatientregndata/?hid=${hid}&searchtext=${val}`);
          // console.log("RRRRRRRRRRRRRRRRR  ========> ", res);
          if (res.data) {
            // const selectedOPD = originalReportData.filter((item) => item.mrNo == mrNoValue);
            console.log("res.data  ========> ", res.data);
            setRecords(res.data);
          } else {
            setRecords([]);
            setNotify({ isOpen: true, message: "Data not found!", type: "error" });
          }
        }, 500);
      } else {
        setRecords(originalReportData);
      }
    } catch (error) {
      console.error("Error handleSearch ====>", error);
      setRecords([]);
      setNotify({ isOpen: true, message: error.message, type: "error" });
      setLoading(false);
    }
  };

  const cancelSearch = () => {
    setSearchVal("");
    setRecords(originalReportData);
    // setOriginalReportData(originalReportData);
  };

  // const handleSearch = async (e) => {
  //   const val = e.target.value;
  //   // const str = val || "";
  //   // console.log("BindBottomTable Value========>", val);
  //   setSearchVal(val);
  //   //if (val) {
  //   // const filteredRows = search(originalReportData, val);
  //   // // console.log("filteredRows =>", filteredRows);
  //   // setOriginalReportData(filteredRows);
  //   //}
  // };

  // const [fieldNamesForSearch] = useState(["name", "fhName", "mrNo", "regNo", "mobileNo", "email", "pAddress"]);
  // function search(allItems, val) {
  //   return allItems.filter((item) => {
  //     console.log("Items", item);
  //     return fieldNamesForSearch.some((newItem) => {
  //       if (newItem === "mobileNo")
  //         return item.mobileNo.pMobNo.toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //       else if (newItem === "pAddress")
  //         return item.pAddress.address.toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //       else return item[newItem].toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //     });
  //   });
  // }

  // const cancelSearch = () => {
  //   setSearchVal("");
  //   // setOriginalReportData(originalReportData);
  // };

  return (
    <>
      <PageHeader title="Patient Registration" icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Controls.Input
            label="Searching by MRNo, RegnNo, Name, FHName, MobNo"
            name="search"
            value={searchVal}
            onChange={handleSearch}
            // title="press tab key for get record"
            // onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
            // error={errors.mrNo}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={cancelSearch} color="primary" sx={{ p: "10px" }} aria-label="directions">
                    <ClearIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                    <SearchIcon />
                    {/* <DirectionsIcon /> */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography color="textSecondary" sx={{ fontSize: 12, textAlign: "right" }}>
            Click the cross button for Fetching today data
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4}></Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Controls.Button
            text="Add Patient"
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
              // setIsSaved(false);
            }}
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{ position: "right", right: "25px" }}
            size="medium"
          />
        </Grid>
      </Grid>
      {/* <div style={{ marginBottom: "40px" }}> */}
      {/* <Toolbar sx={{ justifyContent: "flex-end" }}> 
      </Toolbar> */}
      {/* </div> */}

      <Popup title="Patient Registration Form" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <RegistrationForm
          addOrEdit={addOrEdit}
          recordForEdit={recordForEdit}
          loading={loading}
          setLoading={setLoading}
          isEditRight={isEditRight}
        />
      </Popup>

      <DataGrid
        // heading="Patient Registration Records"
        loading={loading}
        columns={columns}
        rows={records}
        showToolbar={true}
        pageSize={20}
        boxHeight={680}
      />

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <br></br>
    </>
  );

  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
}
